@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;600;700&display=swap');

.App {
  text-align: center;
  background-color: #fff;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: 'Chakra Petch', sans-serif;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

.info {
  margin-top:3rem;
  font-size: 1.5rem;
}